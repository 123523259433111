import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [

    {
        path: '/',
        name: 'Front',
        component: () => import('../views/Front.vue'),
        children: [
            {path: '', name: 'Home1', meta: {name: '系统首页'}, component: () => import('../views/front/Home')},
        ]
    },
    {path: '/login', name: 'Login', meta: {name: '登录'}, component: () => import('../views/Login.vue')},
    {path: '/register', name: 'Register', meta: {name: '注册'}, component: () => import('../views/Register.vue')},
    {
        path: '/reset-password',
        name: 'ResetPassword',
        meta: {name: '重置密码'},
        component: () => import('../views/ResetPassword.vue')
    },
    {
        path:'/home',
        name:'home',
        component:()=>import('../views/home.vue'),
        children: [
            {path: '/index', name: 'index', meta: {name: '首页'}, component: () => import('@/views/home/index')},
            {path:'/authorManagement',name:'authorManagement',meta:{name:'作者管理'},component:()=>import('@/views/home/AuthorManagement.vue')},
            {path:'/profile',name:'profile',meta:{name:'个人主页'},component:()=>import('@/views/home/Profile.vue')},
        ]

    },
    {path: '*', name: 'NotFound', meta: {name: '无法访问'}, component: () => import('../views/404.vue')},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
    if ((to.path === '/login' || to.path === '/register') && user.username) {
        next('/home');
    } else if (!user.username && to.path !== '/login' && to.path !== '/register' && to.path !== '/reset-password') {
        next('/login');
    } else {
        next();
    }
});
export default router
